.terms_section {
  display: flex;
  width: 100%;
  height: auto;
  flex-direction: column;
  padding: 2rem;
  margin-left: auto;
  background-color: #faf9f6;

  h2 {
    padding: 22px;
    text-align: center;
  }
  p {
    font-style: normal;
  }

  .terms {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 10vh;
    flex-direction: column;

    button {
      display: flex;
      &.__btn_home {
        width: 9rem;
        justify-content: center;
        padding: 7px;
        cursor: pointer;
        background-color: transparent;
        font-size: 12px;
        font-weight: bold;
        border: 1px solid gray;
        margin-left: auto;
        &:hover {
          background-color: #fff;
        }
      }
    }

    .__container {
      width: 100%;
      height: 100%;
    }
  }
}

.__container_form {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  color: #fff;

  .__form_element {
    margin-top: 0.5rem;
    font-size: 22px;
    background-color: transparent;

    label {
      display: block;
      font-size: 2rem;
    }

    input,
    textarea {
      border: none;
      border-bottom: 0.1rem solid #e6e6e6;
      width: 90%;
      margin-bottom: 1rem;
      font-size: 2rem;
      padding: 0.9rem 0;
      outline: none;
      background-color: transparent;
      color: #fff;
      &::placeholder {
        color: #fff;
        font-size: 2rem;
        font-style: normal;
        font-family: "Poppins", sans-serif;
      }
    }
  }

  button {
    height: 3.2rem;
    width: 8rem;
    font-size: 1.5rem;
    border-radius: 1rem;
    background: transparent;
    color: #fff;
    margin-top: 1.2rem;
    cursor: pointer;
    border: 2px solid #e6e6e6;
    margin: auto;
    &:hover {
      color: silver;
    }
  }
}

///////////////////////// RESPONSIVE //////////////////////
@media only screen and (max-width: 600px) {
  .__container_form {
    .__form_element {
      font-size: 14px;
      label {
        font-size: 1rem;
      }
      input,
      textarea {
        margin-bottom: 1rem;
        font-size: 1rem;
        &::placeholder {
          color: #fff;
          font-size: 1rem;
        }
      }
    }

    button {
      height: 2.2rem;
      width: 6rem;
      font-size: 1rem;
      border-radius: 1rem;
    }
  }
}

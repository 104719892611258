.network {
  display: flex;
  justify-content: center;
}

/////////////////// RESPONSIVE /////////////////
@media screen and (max-width: 400px) {
  .network {
    display: flex;
    justify-content: center;
  }
}

.toast_container {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  position: absolute;
}

/* //////////////////////// RESPONSIVE /////////////////////////////// */
@media (max-width: 600px) {
  .toast_container {
    max-width: 195px;
    font-size: 12px;
  }
}

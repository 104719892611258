.loader {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  background-color: #121212;
  opacity: 0.3;
  position: absolute;
  z-index: 999;
}

.maintenance {
  display: flex;
  width: 100%;
  align-items: center;
  height: 100%;
  padding: 5rem;

  .__container {
    overflow: hidden;
    display: block;
    border: 4px solid #000;
    height: 49rem;
    width: 100%;
    background: rgba(255, 255, 255, 0.75);
    flex-direction: column;
    position: relative;

    .__image {
      position: relative;
      display: block;
      width: 100%;
      height: 100%;
      z-index: -1;

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: 100% 76%;
      }
    }
    .__txt {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: transparent;
      color: #fff;
      text-align: center;
      width: 100%;
      height: auto;
      p {
        margin-top: 10px;
        font-size: 30px;
        z-index: 2;
        color: #000;
      }
    }
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 820px) {
  .maintenance {
    padding: 3rem;
    .__container {
      .__image {
        img {
          object-position: 38% 76%;
        }
      }
      .__txt {
        p {
          font-size: 21px;
        }
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .maintenance {
    padding: 2rem;
    .__container {
      height: 36rem;
      .__image {
        img {
          object-position: 39% 76%;
        }
      }
      .__txt {
        p {
          font-size: 19px;
        }
      }
    }
  }
}

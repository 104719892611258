@import "./style/fonts.scss";

body {
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  line-height: 1;
  background: #fff;
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
  width: 100%;
}

a {
  text-decoration: none;
  list-style-type: none;
  color: #fff;
  cursor: pointer;
}

.pageError {
  display: flex;
  justify-content: center;
  width: 70%;
  height: 100%;
  flex-direction: column;
  margin: auto;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  h1 {
    text-align: center;
    padding: 1rem;
  }
  ._image {
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
    img {
      margin-left: auto;
      margin-right: auto;
      display: block;
      max-width: 100%;
      height: auto;
    }
  }
}

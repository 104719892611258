@import "../../style/scss-variables";

.home {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  color: rgb(21, 20, 20);
  position: relative;
  background: rgb(21, 20, 20);

  .__section {
    width: 100%;
    display: flex;
    overflow: hidden;
    height: 95vh;
    margin-top: 5vh;
    justify-content: center;
    padding: 3rem;

    .__box {
      display: flex;
      width: 100%;
      height: 100%;
      //border: 19px solid yellow;
      justify-content: flex-start;
      align-items: center;
      position: relative;
      //padding: 4rem;

      .__container {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
      }

      .__box_content {
        width: 50%;
        height: auto;
        display: flex;
        flex-direction: column;
        margin-left: auto;
        padding: 2rem;

        .__txt {
          width: 100%;
          display: flex;
          margin-left: auto;
          z-index: 3;
          word-wrap: break-word;
          justify-content: center;

          blockquote {
            display: flex;
            width: 100%;
            padding: 1.5rem 0;
            justify-content: flex-end;
            background: rgb(21, 20, 20);
            flex-direction: column;

            p {
              font-size: 24px;
              line-height: 1.2;
              color: gray;
            }

            cite {
              color: silver;
              text-align-last: end;
              width: 100%;
              height: auto;
              // padding: 0.5rem 1rem;
            }
          }
        }
      }
    }
  }
  .__footer {
    width: 100%;
    height: auto;
    display: block;
    z-index: 2;
    padding: 0.5rem;
  }
}
///////////////////////// RESPONSIVE //////////////////////
@media only screen and (max-width: 1444px) {
  .home {
    .__section {
      padding: 0;
      justify-content: center;
      .__box {
        width: 100%;
        padding: 0;
        .__container {
          padding: 3rem;
        }
        .__box_content {
          padding: 0.5rem;
          display: flex;
          position: absolute;
          top: 34px;
          width: 100%;
          .__txt {
            padding: 0;
            width: 100%;
            blockquote {
              width: 100%;
              padding: 2rem;
              p {
                font-size: 16px;
                line-height: 1.2;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .home {
    .__section {
      padding: 0;
      justify-content: center;
      .__box {
        width: 100%;
        padding: 0;
        .__container {
          // display: flex;
          // height: 100%;
          // width: 100%;
          // flex-direction: column;
          position: absolute;
          padding: 1rem;
        }
        .__box_content {
          padding: 0.5rem;
          display: flex;
          position: absolute;
          top: 34px;
          width: 100%;
          .__txt {
            padding: 0;
            width: 100%;
            blockquote {
              // width: 100%;
              // padding: 1.5rem 0;

              // display: flex;
              top: 0;
              padding: 1.5rem;
              p {
                font-size: 16px;
                // line-height: 1.2;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home {
    .__container {
      display: block;
      height: 100%;
      width: 100%;
      flex-direction: column;
      position: absolute;
      padding: 1rem;
    }
    .__section {
      padding: 0;
      justify-content: center;
      .__box {
        width: 100%;
        width: 100%;
        padding: 0;
        // .__box_content {
        //   padding: 0.5rem;
        //   display: flex;
        //   position: absolute;
        //   top: 34px;
        //   padding: 0.5rem;
        //   .__txt {
        //     padding: 0;
        //     width: 100%;
        //     blockquote {
        //       width: 100%;
        //       padding: 1.5rem 0;
        //       display: flex;
        //       top: 0;
        //       padding: 1.5rem;
        //       p {
        //         font-size: 16px;
        //         line-height: 1.2;
        //       }
        //     }
        //   }

        // }
      }
    }
  }
}

.canvas__container {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  margin-bottom: 1rem;
  //border: 4px solid red;
  position: relative;
  justify-content: center;

  .canvas {
    display: flex;
    width: 100%;
    height: 100%;
    cursor: move;
    position: relative;
    //border: 4px solid red;
    margin-top: 4vh;

    &#canvas {
      display: flex;
      width: auto;
      height: auto;
      position: absolute;
    }
  }
}

/////////////////// RESPONSIVE /////////////////
@media screen and (max-width: 1024px) {
  .canvas__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .canvas {
      height: 80vh;
      width: 100%;
      margin-top: 10vh;
    }
  }
}
@media screen and (max-width: 768px) {
  .canvas__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .canvas {
      height: 75vh;
      width: 100%;
      margin-top: 12vh;
    }
  }
}
@media screen and (max-width: 500px) {
  .canvas__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .canvas {
      height: 70vh;
      width: 100%;
      margin-top: 16vh;
    }
  }
}

@media screen and (max-width: 393px) {
  .canvas__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .canvas {
      height: 70vh;
      width: 100%;
      margin-top: 21vh;
    }
  }
}
@media screen and (max-width: 378px) {
  .canvas__container {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    .canvas {
      height: 60vh;
      width: 100%;
      margin-top: 25vh;
    }
  }
}

.contactContainer {
  text-align: center;
  display: flex;
  /* margin-top: 27px; */
  //border: 2px solid red;
  /* text-align: center; */
  justify-content: center;
  align-items: center;
}

.whatsappButton {
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  color: #fff;
  text-decoration: none;
  border-radius: 5px;
  font-size: 16px;
  transition: background-color 0.3s ease;
}

.whatsappButton:hover {
  color: silver;
}

/////////////////// RESPONSIVE /////////////////
@media screen and (max-width: 1024px) {
  .whatsappButton {
    padding: 0;
  }
}

@media screen and (max-width: 850px) {
  .whatsappButton {
    padding: 0;
  }
}

@media screen and (max-width: 400px) {
  .whatsappButton {
    padding: 0;
    font-size: 14px;
  }
}

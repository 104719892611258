footer {
  display: flex;
  height: auto;
  width: auto;
  flex-wrap: wrap;
  bottom: 0;
  justify-content: space-between;
  align-items: center;

  ul {
    display: flex;
    align-items: center;
    text-decoration: none;
    list-style-type: none;
    height: auto;
    width: auto;
    height: 1rem;

    p {
      color: #fff;
      text-transform: uppercase;
      font-style: normal;
      font-family: "Poppins", sans-serif;
      font-size: 12px;
      &:hover {
        color: gray;
      }
    }

    li {
      padding: 0.3rem;

      .__logo {
        display: flex;
        width: 4rem;
        object-fit: contain;
        height: 4rem;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      a {
        color: silver;
        font-style: normal;
        font-family: "Poppins", sans-serif;
        font-size: 12px;
        &:hover {
          color: #fff;
        }
      }
    }

    #__copyright {
      display: block;
    }
  }

  .__action_elements {
    display: flex;
    align-items: center;
    width: 40%;
    height: auto;
    flex-wrap: wrap;
    margin-left: auto;

    button {
      background: transparent;
      color: #fff;
      cursor: pointer;
      border: none;
    }

    #__contact {
      display: block;
      z-index: 4;
      justify-content: center;
      padding: 1.5rem;
      text-align: center;
      width: 40%;
      height: auto;

      p {
        color: silver;
        font-size: 18px;
        text-align: center;
        font-family: "Poppins", sans-serif;
        font-style: normal;
        &:hover {
          color: #000;
          -moz-transform: scale(1.1);
          -webkit-transform: scale(1.1);
          -o-transform: scale(1.1);
          -ms-transform: scale(1.1);
          transform: scale(1.1);
        }
      }
    }

    // #__keepintch {
    //   display: flex;
    //   width: 60%;
    //   height: auto;
    //   padding: 0.5rem;
    // }
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 1024px) {
  footer {
    ul {
      p {
        color: #fff;
        text-transform: uppercase;
        font-style: normal;
        font-family: "Poppins", sans-serif;
        font-size: 8px;
      }
      li {
        padding: 0.3rem;
        width: auto;
        text-align: center;
        .__logo {
          width: 3rem;
          height: 2rem;
        }

        a {
          color: #fff;
          font-size: 10px;
          font-style: normal;
          font-family: "Poppins", sans-serif;
          display: flex;
          &:hover {
            color: #000;
          }
        }
      }
    }
    .__action_elements {
      margin-left: auto;
      width: 100%;
      #__contact {
        p {
          font-size: 14px;
        }
      }
      #__contact {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  footer {
    ul {
      li {
        .__logo {
          width: 1rem;
          height: 1rem;
        }
      }
    }
    .__action_elements {
      margin-left: auto;
      width: 100%;
      height: auto;
      #__contact {
        padding: 0;
        p {
          font-size: 14px;
        }
      }
    }
  }
}

.txts {
  display: flex;
  width: 39%;
  height: 100%;
  padding: 3rem;

  ul {
    list-style-type: none;

    li {
      blockquote {
        width: 100%;
        height: auto;
        padding: 0.5rem;
        display: block;
        h1 {
          font-size: 5rem;
          padding: 2rem;
          text-align: center;
          color: #999797;
        }

        p {
          color: #fff;
          font-size: 18px;
          line-height: 1.3;
        }
        cite {
          color: silver;
          text-align-last: end;
          width: 100%;
          height: auto;
          padding-top: 0.5rem;
        }
      }
    }
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 1024px) {
  .txts {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1rem;
    ul {
      padding-top: 2rem;
      li {
        blockquote {
          width: 100%;
          height: auto;
          padding-top: 1rem;
          display: block;
          h1 {
            font-size: 2rem;
            padding: 2rem;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .txts {
    display: flex;
    width: 100%;
    height: 100%;
    padding: 1rem;
    ul {
      padding-top: 0;
      li {
        blockquote {
          width: 100%;
          height: auto;
          display: block;
          h1 {
            font-size: 2rem;
            padding: 2rem;
          }
        }
      }
    }
  }
}

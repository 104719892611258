@import "../../style/scss-variables";

.header {
  display: flex;
  width: 100%;
  height: 5rem;
  text-transform: capitalize;
  z-index: 4;
  align-items: center;
  background: rgb(21, 20, 20);
  position: fixed;
  //border: 4px solid yellow;

  .__logo {
    display: flex;
    width: 23rem;
    object-fit: contain;
    height: 10rem;
    padding: 1rem;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 7;
    }
  }

  .__container_desk {
    display: flex;
    width: 100%;
    height: auto;
    background-color: transparent;

    .__menu_desk {
      display: flex;
      width: 70%;
      height: auto;
      align-items: center;
      z-index: 7;

      .__listItem_btn {
        background: transparent;
        border: none;
        outline: none;

        li {
          font-size: 35px;
          text-decoration: none;
          list-style-type: none;
          color: gray;
          cursor: pointer;
          padding: 24px;
          background: transparent;
          text-transform: capitalize;
          &:hover {
            color: #fff;
          }
        }
      }
    }
  }

  .__container_mobile {
    width: 100%;
    height: 100%;
    cursor: pointer;
    opacity: 1;
    display: flex;
    padding: 1rem;

    .__burger_btn {
      border: none;
      background: none;
      cursor: pointer;
      z-index: 4;
      display: block;
      height: auto;
      width: auto;
      align-items: flex-end;
      align-content: end;
      margin-left: auto;
      img {
        width: 46px;
      }
    }

    .__menu_mobile {
      display: flex;
      border: none;
      align-items: center;
      justify-content: center;
      position: fixed;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);

      .__listItem_mobile_btn {
        display: flex;
        padding: 1rem;
        background: transparent;
        border: none;
        width: 100%;
        height: auto;
        justify-content: center;

        li {
          text-transform: capitalize;
          font-size: 35px;
          text-decoration: none;
          list-style-type: none;
          color: #fff;
          cursor: pointer;
          text-align: center;
          text-transform: capitalize;
          &:hover {
            color: silver;
          }
        }
      }
    }
  }

  .__insta_link {
    display: flex;
    width: auto;
    height: auto;
    align-items: center;
    width: auto;
    justify-content: flex-end;
    margin-right: 2rem;
    cursor: pointer;

    p {
      font-size: 29px;
      text-align: center;
      justify-content: center;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: bolder;
      padding: 2rem;
      color: gray;
      &:hover {
        color: silver;
      }
    }
  }
}

///////////////////////// RESPONSIVE //////////////////////

@media only screen and (min-width: 1026px) {
  .__container_desk {
    display: flex !important;
    .__list_header {
      display: flex !important;
    }
  }
  .__container_mobile {
    display: none !important;
  }
}

@media only screen and (max-width: 1024px) {
  .header {
    .__logo {
      display: flex;
      width: 6rem;
      object-fit: contain;
      height: auto;
    }

    .__container_desk {
      display: none !important;
      .__list_header {
        display: none !important;
      }
    }
    .__container_mobile {
      display: flex;
    }
  }
}

@media only screen and (max-width: 820px) {
  .__container_desk {
    display: none;
    .__list_header {
      display: none;
    }
  }
  .__container_mobile {
    display: flex;
  }
}

@media only screen and (max-width: 600px) {
  .header {
    .__container_desk {
      display: none;
      .__list_header {
        display: none;
      }
    }
    .__container_mobile {
      display: flex;
    }
    .__insta_link {
      margin-right: 0;
      p {
        padding: 1rem;
        font-size: 20px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .__container_desk {
    display: none;
    .__list_header {
      display: none;
    }
  }
  .__container_mobile {
    display: flex;
  }
  .___insta_link {
    p {
      font-size: 25px;
      text-align: center;
      justify-content: center;
      font-family: "Poppins", sans-serif;
      font-style: normal;
      font-weight: bolder;
      padding: 2rem;
      color: blui;
    }
  }
}

@media only screen and (max-width: 350px) {
  .__container_desk {
    display: none;
    .__list_header {
      display: none;
    }
  }
  .__container_mobile {
    display: flex;
  }
  .__insta_link {
    p {
      font-size: 25px;
    }
  }
}

.overlay {
  display: flex;
  width: 100%;
  height: auto;
  background-color: #121212;
  // background-color: rgba(0, 0, 0, 0.9);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 5;

  #__bg {
    display: flex;
    width: 42%;
    height: 100%;
  }
  img {
    object-fit: cover;
    opacity: 0.2;
  }

  .__modalContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .__modal_content {
      width: 80%;
      height: auto;

      .__closeBtn {
        position: fixed;
        top: 8px;
        right: 8px;
        width: auto;
        height: auto;
        cursor: pointer;
        padding-right: 1rem;
        p {
          font-size: 1.5rem;
          font-style: normal;
          color: #fff;
          text-transform: capitalize;
          &:hover {
            color: silver;
          }
        }
      }

      .__content {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: flex-end;

        .__content_form {
          display: flex;
          width: 100%;
          height: 100%;
          overflow: scroll;
          overflow-x: hidden;
          overflow-y: scroll;
        }
        .__content_text {
          display: flex;
        }
      }
    }
  }
}

//////////////////////// RESPONSIVE ///////////////////////////////
@media only screen and (max-width: 768px) {
  .overlay {
    #__bg {
      display: flex;
      width: 100%;
      height: 100%;
    }
    .__modalContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}
